@import "assets/styles/variables.scss";

.banner-text {
  padding: 20px;
  margin-left: 20px;
  position: relative;
  bottom: 85px;
  max-width: 40%;

  &.white {
    color: $color-white;
    background-color: $color-black;
  }

  &.black {
    color: $color-black;
    background-color: $color-white;
  }

  &.blue {
    color: $color-white;
    background-color: $color-blue-4;
  }
}

.banner-cta {
  position: absolute;
  top: 185px;
  right: 40px;
}
