@import "assets/styles/variables.scss";

.custom-form-input-group {
  input {
    padding: 22px 183px 21px 17px;
    border-color: $color-blue-1 !important;
  }

  input,
  textarea {
    &:focus,
    &:active {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  textarea {
    padding: 15px 183px 128px 17px;
    border-color: $color-blue-1 !important;
  }

  ::-webkit-input-placeholder {
    color: $color-gray-4 !important;
  }
}
