@import "assets/styles/variables.scss";

.gallery-link {
  .gallery-label {
    transition: $transition-slow;
    position: relative;
    bottom: 25px;
  }

  &:hover {
    .gallery-label {
      transform: translateY(-0.5rem);
    }
  }
}
