// Main color utils
.tc-orange {
  color: $color-orange;
}

.tc-white {
  color: $color-white;
}

.tc-black {
  color: $color-black;
}

// Gray color utils
.tc-gray-1 {
  color: $color-gray-1;
}

.tc-gray-2 {
  color: $color-gray-2;
}

.tc-gray-3 {
  color: $color-gray-3;
}

.tc-gray-4 {
  color: $color-gray-4;
}

// Blue color utils
.tc-blue-1 {
  color: $color-blue-1;
}

.tc-blue-2 {
  color: $color-blue-2;
}

.tc-blue-3 {
  color: $color-blue-3;
}

.tc-blue-4 {
  color: $color-blue-4;
}

.tc-blue-5 {
  color: $color-blue-5;
}

// Hover utils
.thc-white {
  &:hover {
    color: $color-white;
  }
}
