@import "assets/styles/variables.scss";

.button-orange {
  background: linear-gradient(
      to right,
      var(--c1, $color-orange) 60%,
      var(--c2, $color-gray-1) 1%
    )
    var(--x, 100%) / 200%;
  padding: 12px 12px 12px 12px;
  transition: $transition;

  &:hover {
    text-decoration: none;
    --x: 0%;
  }
}
