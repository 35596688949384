@import "assets/styles/variables.scss";

.about-us {
  .img-wrap {
    img {
      position: relative;
      z-index: 2;
      width: 100%;
      max-width: 380px !important;
    }
  }
  .rectangle-ltr {
    position: absolute;
    bottom: -5%;
    left: 7%;
    z-index: 1;
    width: 280px;
    background-color: $color-orange;
    @media only screen and (max-width: $xl-size-start - 1) {
      width: 230px;
    }
    @media only screen and (max-width: $md-size-start - 1) {
      left: 17%;
    }
    @media only screen and (max-width: 375px - 1) {
      left: 10%;
    }
    @media only screen and (max-width: 300px - 1) {
      left: 0;
      height: 30px;
    }
  }
  .rectangle-rtl {
    position: absolute;
    bottom: -8%;
    right: 7%;
    z-index: 1;
    width: 280px;
    background-color: $color-orange;
    @media only screen and (max-width: $xl-size-start - 1) {
      width: 230px;
    }
    @media only screen and (max-width: $lg-size-start - 1) {
      right: 10%;
    }
    @media only screen and (max-width: $md-size-start - 1) {
      right: 17%;
    }
    @media only screen and (max-width: 375px - 1) {
      right: 10%;
    }
    @media only screen and (max-width: 300px - 1) {
      right: 6%;
      height: 30px;
    }
  }
  .pdf-link {
    svg {
      transform: translateY(-1px);
    }
  }
}
