// Text font size utils
.tfs-very-small {
  font-size: 0.75em;
}

.tfs-small {
  font-size: 0.875em;
}

.tfs-normal {
  font-size: 1em;
}

.tfs-large {
  font-size: 1.125em;
}

.tfs-very-large {
  font-size: 1.25em;
}

.tfs-very-very-large {
  font-size: 2rem;
}
