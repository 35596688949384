@import "assets/styles/variables.scss";

.button-orange-border {
  background: transparent;
  padding: 13px 10px 12px 10px;
  border: solid 1px $color-orange;
  transition: $transition;
  max-width: 120px;

  &:hover {
    background-color: $color-orange;
    text-decoration: none;
  }
}
