@import "assets/styles/variables.scss";

.custom-submit-button {
  border: 1px solid $color-blue-1 !important;
  padding: 12px 63px 11px 62px;
  color: $color-gray-4 !important;
  &:hover {
    background-color: $color-blue-1 !important;
  }
  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}
