@import "assets/styles/variables.scss";

.form-group {
  position: relative;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid $color-white;
    -webkit-text-fill-color: $color-black;
    transition: background-color 5000s ease-in-out 0s;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: rgba(92, 184, 92, 0.4) !important;
    transition: 0.3s ease all;
    color: $color-black;
    border: none !important;
  }
  .submit-button {
    position: relative;
    transition: $transition;
    color: $color-orange;
    button {
      transition: $transition;
      white-space: nowrap;
      &:focus {
        color: $color-orange !important;
      }
    }
    &:after {
      content: "";
      width: 102%;
      position: absolute;
      left: 0;
      bottom: 0;
      border-width: 0 0 2px;
      border-style: solid;
    }
    &:hover {
      button {
        padding-right: 0.7rem !important;
        opacity: 0.8;
        color: $color-orange;
      }
    }
  }
  label {
    position: absolute;
    transform: translate(0, 9px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;
  }
  &:focus-within label {
    padding: 0 15px;
    transform: translate(-20px, -15px) scale(0.75);
    color: $color-orange;
    background-color: #fff;
  }
}
.form-group label.active {
  transform: translate(-20px, -15px) scale(0.75);
  color: $color-orange;
  background-color: #fff;
  padding: 0 15px;
}
@media only screen and (max-width: $md-size-start - 1) {
  .form-group {
    border: none !important;
    input {
      border: 1px solid $color-black !important;
      margin-bottom: 1.25rem;
      padding: 20px;
    }
    .submit-button {
      button {
        text-decoration: underline;
      }
      transition: $transition;
      &:after {
        display: none;
      }
      &:hover {
        button {
          padding-right: 0 !important;
          opacity: 0.8;
        }
      }
    }
    label {
      padding: 0 10px 0 20px;
      transform: translate(-10px, -70px) scale(0.75) !important;
      color: $color-orange;
      background-color: #fff;
    }
    &:focus-within label {
      padding: 0 10px 0 20px !important;
    }
  }
}
