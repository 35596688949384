@import "assets/styles/variables.scss";

.navigation-item-dropdown {
  &:hover {
    background-color: $color-gray-2;
  }

  &:focus,
  &.active {
    color: $color-white;
    background-color: $color-blue-4;
  }
}
