@import "variables.scss";

@import "~bootstrap/scss/bootstrap";
@import "fonts.scss";

@import "text-size-utils.scss";
@import "text-color-utils.scss";
@import "background-color-utils.scss";
@import "utils.scss";

html,
body {
  font-family: $font-latto, sans-serif;
  font-size: 16px;
  color: $color-black;
}

h1,
h2 {
  font-family: $font-unna, serif;
}

h1 {
  font-size: 3.75em;

  @media (max-width: $lg-size-start - 1) {
    font-size: 2.5em;
  }
}

h2 {
  font-size: 2.25em;

  @media (max-width: $lg-size-start - 1) {
    font-size: 1.75em;
  }
}

h3 {
  font-size: 1.75em;

  @media (max-width: $lg-size-start - 1) {
    font-size: 1.5em;
  }
}

h4 {
  font-size: 1.5em;

  @media (max-width: $lg-size-start - 1) {
    font-size: 1.25em;
  }
}

h5 {
  font-size: 1.25em;

  @media (max-width: $lg-size-start - 1) {
    font-size: 1em;
  }
}

h6 {
  font-size: 1em;

  @media (max-width: $lg-size-start - 1) {
    font-size: 0.75em;
  }
}

a {
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: underline;
  }
}

// Global font sized
.global-font-75 {
  font-size: 0.75rem;
}

.global-font-100 {
  font-size: 1rem;
}

.global-font-125 {
  font-size: 1.25rem;
}

.global-font-150 {
  font-size: 1.5rem;
}

.global-font-175 {
  font-size: 1.75rem;
}

//global marker, placeholder color
::marker {
  color: $color-orange;
}
::placeholder {
  color: $color-black !important;
  font-size: 0.875em;
}
//global section y padding
.section-py {
  padding-top: 5.75rem;
  padding-bottom: 5.75rem;
  @media only screen and (max-width: $md-size-start) {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0.2;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
}
