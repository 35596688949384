@import "assets/styles/variables.scss";

.main-activities {
  img {
    position: relative;
    z-index: 2;
    width: 100%;
  }
  .square {
    top: 0;
    right: 0 !important;
    left: auto;
    width: 130px;
    height: 127px;
  }
}
@media only screen and (max-width: $lg-size-start) {
  .main-activities {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
    img {
      width: 100%;
      height: 220px;
      object-fit: cover;
    }
  }
}
