@import "assets/styles/variables.scss";

.publication-card-deck {
  justify-content: center;
}

@media only screen and (max-width: $lg-size-start) {
  .publication-card-deck {
    justify-content: center;
  }
}

@media only screen and (max-width: $sm-size-start) {
  .publication-card-deck {
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
  }
}
