// Main background color utils
.bc-orange {
  background: $color-orange;
}

.bc-orange-light {
  background: $color-orange-light;
}

.bc-white {
  background: $color-white;
}

.bc-black {
  background: $color-black;
}

// Gray background color utils
.bc-gray-1 {
  background: $color-gray-1;
}

.bc-gray-2 {
  background: $color-gray-2;
}

.bc-gray-3 {
  background: $color-gray-3;
}

.bc-gray-4 {
  background: $color-gray-4;
}

// Blue background color utils
.bc-blue-1 {
  background: $color-blue-1;
}

.bc-blue-2 {
  background: $color-blue-2;
}

.bc-blue-3 {
  background: $color-blue-3;
}

.bc-blue-4 {
  background: $color-blue-4;
}

.bc-blue-5 {
  background: $color-blue-5;
}

// Mixed background color utils
.bc-blue4-gray1 {
  background: linear-gradient(180deg, $color-blue-4 50%, $color-gray-1 50%);
}
