@import "assets/styles/variables.scss";

.publications-card {
  transition: $transition-slow;

  &.small-md {
    width: 170px;
  }

  .card-text {
    color: $color-gray-4;
  }

  span {
    background: linear-gradient(transparent 67%, $color-gray-2 33%);
    padding: 0 25px 10px 25px;
  }

  img {
    width: 100%;
    height: 170px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    object-fit: cover;
  }

  &:hover {
    transform: translateY(-0.5rem);

    .square {
      background-color: $color-orange-light;
    }
  }
}

@media only screen and (max-width: $lg-size-start) {
  .publications-card {
    width: 200px;

    &.small-md {
      width: 150px;
    }
  }
}

@media only screen and (max-width: $md-size-start) {
  .publications-card {
    span {
      background: linear-gradient(transparent 70%, $color-gray-2 30%);
      padding: 0 20px 10px 20px;
    }
  }
}

@media only screen and (max-width: $sm-size-start) {
  .publications-card {
    width: 140px;

    img {
      height: 120px;
    }

    .card-title {
      white-space: nowrap;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .publications-card,
  .news-card {
    .card-text,
    .card-title {
      font-size: 0.875em;
    }
  }
}

@media only screen and (max-width: $xs-size-start) {
  .publications-card {
    width: 105px;

    .wrap {
      span {
        padding: 0 15px 10px 15px;
      }
    }
  }
}
