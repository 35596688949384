@import "assets/styles/variables.scss";

.mobile-menu-list {
  a {
    &.active {
      text-decoration: underline;
      color: $color-blue-2;
    }
  }
}
