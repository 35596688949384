/**
 * Lato fonts have been downloaded from https://www.latofonts.com/lato-free-fonts/
 */

// Lato light
@font-face {
  font-family: $font-latto;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/Lato-Light.woff2) format("woff2");
}

// Lato regular
@font-face {
  font-family: $font-latto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/Lato-Regular.woff2) format("woff2");
}

// Lato bold
@font-face {
  font-family: $font-latto;
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/Lato-Bold.woff2) format("woff2");
}

// Unna regular and regular italic
@import url("https://fonts.googleapis.com/css2?family=Unna:ital@0;1&display=swap");
