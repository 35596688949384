@import "assets/styles/variables.scss";

.btn-nav {
  background: none;
  border: none;

  &:hover,
  &.active {
    background-color: $color-blue-5 !important;
    color: $color-white;
  }

  &:active,
  &:focus {
    background-color: $color-blue-5 !important;
    box-shadow: none !important;
  }

  // Set the arrow size
  &::after {
    vertical-align: 0.2em;
    border-top: 0.23em solid;
    border-right: 0.23em solid transparent;
    border-left: 0.23em solid transparent;
  }

  // Handle dropdown toggle background effects
  &.dropdown-toggle {
    &:not(:active) {
      background: none;
    }
  }
}
