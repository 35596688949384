// Colors
$color-white: #fff;
$color-orange: #e79f3c;
$color-orange-light: #e79f3c4d;
$color-black: #131313;

$color-gray-1: #fafafb;
$color-gray-2: #ebebeb;
$color-gray-3: #ffffff99;
$color-gray-4: #7d7d7d;

$color-blue-1: #d1dbe3;
$color-blue-2: #9dafbd;
$color-blue-3: #758ea3;
$color-blue-4: #004a87;
$color-blue-5: #003d6f;

// Media break points
$xs-size-start: 0;
$sm-size-start: 576px;
$md-size-start: 768px;
$lg-size-start: 1100px;
$xl-size-start: 1500px;

// Bootstrap grid breakpoints
$grid-breakpoints: (
  xs: $xs-size-start,
  sm: $sm-size-start,
  md: $md-size-start,
  lg: $lg-size-start,
  xl: $xl-size-start,
) !default;

// Transition speeds
$transition: 0.2s ease all;
$transition-slow: 0.4s ease all;

// Fonts
$font-unna: "Unna";
$font-latto: "Lato";
