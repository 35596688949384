@import "assets/styles/variables.scss";
@import "assets/styles/text-size-utils.scss";

$item-margin: 5px;
$item-padding-y: 8px;
$item-padding-x: 12px;

.custom-pagination {
  li {
    margin: 0 $item-margin 0 $item-margin;

    a {
      padding: $item-padding-y $item-padding-x $item-padding-y $item-padding-x;
      border: none;
      background-color: $color-gray-2;
      color: $color-black;

      &:hover {
        background-color: $color-orange;
        color: $color-white !important;
      }
    }

    &.active {
      a {
        background-color: $color-orange;
        color: $color-white;
      }
    }

    &.next {
      margin-right: 0;
    }

    &.previous {
      margin-left: 0;
    }

    &.next,
    &.previous,
    &.break-me {
      margin: 0;

      a {
        background-color: transparent;

        &:hover {
          color: $color-orange !important;
        }
      }
    }

    a {
      outline: none;
    }
  }
}
