@import "assets/styles/variables.scss";

.square {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 20px;
  height: 20px;
  background-color: $color-orange;
}

.news-card {
  transition: $transition-slow;

  .card-title,
  img {
    max-width: 300px;
  }

  span {
    z-index: 2;
    height: 160px;

    img {
      height: 160px;
      object-fit: cover;
    }
  }

  &:hover {
    transform: translateY(-0.5rem);

    .square {
      background-color: $color-orange-light;
    }
  }
}

@media only screen and (max-width: $lg-size-start) {
  .card-title,
  img {
    max-width: 100% !important;
  }
}

@media only screen and (max-width: $sm-size-start) {
  .news-card {
    min-width: 0 !important;
  }
}
