@import "assets/styles/variables.scss";

.carousel {
  height: 400px;
  background: $color-white;
  img {
    height: 400px;
    flex-grow: 0;
    object-fit: cover;
  }
}
.carousel-control-prev {
  display: none;
}
.carousel-control-next {
  width: 60px;
  height: 60px;
  padding: 11px 10px 9px;
  opacity: 1;
  top: auto;
  background-color: $color-orange;
  &:hover {
    .carousel-control-next-icon {
      transform: translateX(0.2rem);
    }
    opacity: 1;
  }
  &:focus {
    opacity: 1;
  }
}
.carousel-control-next-icon {
  transition: $transition;
  background-image: url(../../assets/arrow-next.svg);
  width: 40px;
  height: 40px;
  object-fit: contain;
  color: $color-white;
}
.carousel-caption {
  padding-bottom: 0;
  bottom: 50%;
  color: $color-gray-1;
}

@media (max-width: $sm-size-start) {
  .carousel {
    height: 300px;
    img {
      height: 300px;
    }
  }
  .carousel-control-next {
    width: 40px;
    height: 40px;
    padding: 7px 7px 6px;
  }
  .carousel-control-next-icon {
    width: 26px;
    height: 27px;
  }
}
