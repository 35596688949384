@import "assets/styles/variables.scss";

.wysiwyg {
  a {
    color: $color-orange;

    &:hover {
      text-decoration: underline;
    }
  }
}
