@import "assets/styles/variables.scss";

.google-maps-holder {
  .google-iframe {
    z-index: 2;
  }
  .square {
    width: 180px;
    height: 180px;
    left: 75%;
    top: -4%;
    z-index: 1;
    @media only screen and (max-width: $xl-size-start - 1) {
      left: 70%;
    }
    @media only screen and (max-width: $lg-size-start - 1) {
      left: 51.5%;
    }
    @media only screen and (max-width: $md-size-start - 1) {
      left: 69%;
    }
  }
}
