@import "assets/styles/variables.scss";

.bm-cross-button {
  display: none;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

.bm-item-list {
  background: $color-blue-4;
}

.bm-item {
  display: inline-block;
  width: 100%;
  outline: 0;
}
