// List utils
ul.flat-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    zoom: 1;
    *display: inline;
  }
}

ul.no-style-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

// Font line height utils
.tlh-1 {
  line-height: 1;
}

// Cursor utils
.cursor-pointer {
  cursor: pointer;
}

// Hover text decorations
.th-underline {
  &:hover {
    text-decoration: underline;
  }
}

.th-no-underline {
  &:hover {
    text-decoration: none;
  }
}
